<template>
  <section>
    <lottie-player v-if="!isImage" :src="path" class="refusal-image" autoplay speed="1" loop />

    <div v-else class="text-center">
      <img
        :src="path"
        alt="image"
      />
    </div>

    <h3>{{ title }}</h3>

    <p>{{ text }}</p>

    <main-button
      class="p-button"
      :label="buttonText"
      @click="logout"
    />
  </section>
</template>

<script>
import MainButton from './buttons/MainButton';
import localize from '../filters/localize.filter';
import VueTypes from 'vue-types';

export default {
  name: 'EmptyState',
  props: {
    image: VueTypes.string,
    animation: VueTypes.object,
    title: VueTypes.string.isRequired,
    text: VueTypes.string,
    isImage: VueTypes.bool.def(true),
    buttonText: VueTypes.string,
  },
  computed: {
    path() {

      if (this.isImage) {
        return this.image;
      }

      return JSON.stringify(this.animation);
    },
  },
  components: {
    MainButton,
  },
  methods: {
    localize,
    logout() {
      this.$cookieRemove('token');
      localStorage.removeItem('user_info');
      localStorage.removeItem('number');
      localStorage.removeItem('card_hash');
      localStorage.removeItem('hash');
      localStorage.removeItem('check');
      localStorage.removeItem('user_number');

      this.$store.dispatch('buyers/signOut');
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
  section {
    background: $grey;
    height: 100vh;
    padding-top: 80px;
  }

  .refusal-image {
    width: 220px;
    height: 220px;
    margin: auto;
  }

  h3 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 24px;
    color: $black;
  }

  p {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $black;
    margin-top: 24px;
  }

  button.p-button {
    width: auto;
    display: flex;
    margin: 24px auto;
  }
</style>