<template>
  <empty-state
    :is-image="true"
    :image="image"
    :title="localize('error')['emptyPage']['otherContract']"
    :button-text="localize('error')['emptyPage']['otherContractLogout']"
  />
</template>

<script>
import EmptyState from '../../components/EmptyState';
import otherContract from '../../assets/img/not-verified.png';
import localize from '../../filters/localize.filter';

export default {
  name: 'EmptyPage',
  components: {
    EmptyState,
  },
  data: () => ({
    image: otherContract
  }),
  methods: {
    localize
  }
};
</script>

<style scoped>

</style>